<template>
  <div>
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="12" class="my-auto">
          <h3 class="primary--text">
            INFORMASI JABATAN - {{ unor_name.toUpperCase() }}
          </h3>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card class="pl-5 pt-5 pb-4">
      <organization-chart
        :datasource="ds"
        @node-click="(nodeData) => nodeClick(nodeData)"
      >
        <template slot-scope="{ nodeData }">
          <v-card class="px-2" outlined>
            <small v-if="nodeData.title">
              {{ nodeData.title.toUpperCase() }}
            </small>
          </v-card>
        </template>
      </organization-chart>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import OrganizationChart from "vue-organization-chart";

import SView from "@/store/anjab/infojabatan/view";

export default {
  components: {
    OrganizationChart,
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),

    ds: {},
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url =
        process.env.VUE_APP_API + "anjab/unor/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.ds = this.convert(res.data.mapData[0]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    Ftgl(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    convert(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "namaJabatan") {
          this.title = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else {
          return v;
        }
      });

      return parsed;
    },

    nodeClick(value) {
      this.$router.push("/anjab/infojabatan/detail/" + value.id).catch(() => {});
    },
  },
};
</script>